<template>
  <div class="A90">
    <div class="banner">
      <div class="textBox">
        <div class="title">Agility A-90</div>
        <div class="smallTitle">2D Desktop Barcode Scanner</div>
        <div class="iconTextBox">
          <div class="left">
            <img src="../../../assets/9520网站配图/图标/路径 5.png" alt="" />
            Barcode
          </div>
          <div class="right">
            <img src="../../../assets/9520网站配图/图标/路径 4.png" alt="" />
            Payment Code
          </div>
        </div>
      </div>
    </div>
    <div class="Features">
      <div class="center">
        <div class="left">
          <div class="title">A-90 Features</div>
          <ul>
            <li>
              <i></i>
              <div>Equipped with new decoding chip & million-pixel camera</div>
            </li>
            <li>
              <i></i>
              <div>
                Advanced decoding algorithm, 2m/s tolerance, and wide reading
                angle for seamless high-speed scanning.
              </div>
            </li>
            <li>
              <i></i>
              <div>
                Detachable base & embedded shell for easy counter attachment.
              </div>
            </li>
            <li>
              <i></i>Supports 1D & toggle 2D barcode scanning via touch buttons for diverse scenarios.
            </li>
          </ul>
        </div>
        <div class="right">
          <img src="../../../assets/A90配图/PC/矢第二页.png" alt="" />
        </div>
      </div>
    </div>
    <div class="product">
      <img src="../../../assets/A90配图/PC/标注.png" alt="" />
    </div>
    <div class="Agility">
      <div class="w">
        <div class="titleBox">
          <div class="title">Agility A-90</div>
          <!-- <div class="title">Agility 9520</div> -->
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">Print contrast ratio</div>
              <div class="text">≥20%</div>
            </div>
            <div class="itemA">
              <div class="title">Light Source</div>
              <div class="text">LED Red light</div>
              <div class="text">(Osram from Germany)</div>
            </div>
            <div class="itemA">
              <div class="title">Base</div>
              <div class="text">Detachable</div>
            </div>
            <div class="itemA">
              <div class="title">Image Sensor</div>
              <div class="text">1.3 Million pixels Global Shutter</div>
            </div>
            <div class="itemA">
              <div class="title">Resolution</div>
              <div class="text">0.102mm / 4mils</div>
            </div>
            <div class="itemA">
              <div class="title">Motion Tolerance</div>
              <div class="text">2M/sec</div>
            </div>
            <div class="itemA">
              <div class="title">Reading Indicators</div>
              <div class="text">LED light&Buzzer</div>
              <div class="text">(adjustable tone and volume)</div>
            </div>
            <div class="itemA">
              <div class="title">Input Voltage</div>
              <div class="text">5 VDC (+/- 10%)</div>
            </div>
            <div class="itemA">
              <div class="title">IESD protection</div>
              <div class="text">Touch 8 kV; Air 15kV</div>
            </div>
            <div class="itemA">
              <div class="title">Humidity</div>
              <div class="text">5 - 95%</div>
            </div>
            <div class="itemB">
              <div class="title">Current</div>
              <div class="text">Working current (Standard):</div>
              <div class="text">&lt; 430 mA</div>
              <div class="text">Standby current (Standard):</div>
              <div class="text">&lt; 220 mA</div>
            </div>
            <div class="itemB">
              <div class="title">Reading Angle</div>
              <div class="text">Pitch: 0-360° ;</div>
              <div class="text">Roll(Tilt): ± 60° ;</div>
              <div class="text">Skew (Yaw): ± 65°</div>
            </div>
            <div class="itemB">
              <div class="title">Ambient light</div>
              <div class="text">0 -100,000 lux</div>
            </div>
            <div class="itemC">
              <div class="title">Interfaces</div>
              <div class="text">
                RS-232; USB HID; USB COM; HID POS available as per customer’s
                needs
              </div>
            </div>
            <div class="itemD">
              <div class="title">Typical depth of Field</div>
              <div class="table">
                <div class="item">
                  <div class="text">Precision</div>
                  <div class="text">5mil</div>
                  <div class="text">5mil</div>
                  <div class="text">13.34mil</div>
                  <div class="text">10mil</div>
                  <div class="text">10mil</div>
                  <div class="text">20mil</div>
                  <div class="text">20mil</div>
                </div>
                <div class="item">
                  <div class="text">Barcode</div>
                  <div class="text">CODE128</div>
                  <div class="text">EAN-13</div>
                  <div class="text">EAN-13</div>
                  <div class="text">QR</div>
                  <div class="text">PDF417</div>
                  <div class="text">QR</div>
                  <div class="text">DM</div>
                </div>
                <div class="item">
                  <div class="text">Depth of Field</div>
                  <div class="text">0mm—90mm</div>
                  <div class="text">0mm—90mm</div>
                  <div class="text">0mm—280mm</div>
                  <div class="text">0mm—110mm</div>
                  <div class="text">0mm—260mm</div>
                  <div class="text">0mm—200mm</div>
                  <div class="text">2mm—110mm</div>
                </div>
              </div>
            </div>
            <div class="itemE">
              <div class="title">Temperature</div>
              <div class="text">Operation: -10 to 50 °C</div>
              <div class="text">Storage/Transport: -20 to 65 °C</div>
            </div>
            <div class="itemF">
              <div class="title">Decoding Capability</div>
              <div class="text">
                1D: All standard 1D codes, including GS1 DataBar™ linear code
                can be automatically distinguished and decoded.
              </div>
              <div class="text">
                2D: Aztec Code; Data Matrix; MaxiCode; QRCode; HANXIN; MicroQR
                Code Stack code: GS1 DataBar Expanded Stacked; GS1 DataBar
                Stacked; GS1 DataBar
              </div>
              <div class="text">
                Stacked Omnidirectional; GS1 DataBar Composite; MicroPDF417;
                PDF417.
              </div>
            </div>
            <div class="itemG">
              <div class="title">Dimensions</div>
              <div class="rightImg"></div>
            </div>
          </div>
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">A-90</div>
              <div class="text">PDF(1.18MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/Agility_A-90.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <div class="itemB">
              <div class="title">A-90 User Manual</div>
              <div class="text">PDF(482KB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/Agility_A-90_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.A90 {
  .banner {
    position: relative;
    height: 1000px;
    background: url('../../../assets/A90配图/PC/首页.png') no-repeat center;
    background-size: cover;
    .textBox {
      position: absolute;
      top: 101px;
      left: 1000px;
      .title {
        color: rgba(0, 92, 255, 1);
        font-size: 72px;
        font-weight: 600;
        line-height: 95.47px;
      }
      .smallTitle {
        margin: 16px 0px 60px 0px;
        color: #fff;
        font-size: 36px;
        line-height: 52.13px;
      }
      .iconTextBox {
        img {
          margin-right: 10px;
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 47px;
            height: 33.04px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          img {
            width: 36.37px;
            height: 33.17px;
          }
        }
        display: flex;
        justify-content: space-between;
        width: 430px;
        display: flex;
        color: #fff;
        font-size: 24px;
        line-height: 34.75px;
      }
    }
  }
  .Features {
    display: flex;
    justify-content: center;
    height: 800px;
    background: #000;
    .center {
      display: flex;
    }
    .left {
      margin-top: 129px;
      margin-right: 30px;
      .title {
        margin-bottom: 46px;
        color: #fff;
        font-size: 36px;
        font-weight: 600;
      }
      ul {
        li {
          text-align: justify;
          display: flex;
          width: 674px;
          margin-bottom: 55px;
          line-height: 34.75px;
          color: #fff;
          font-size: 22px;
          i {
            margin: 5px 8px 0px 0px;
            width: 0;
            height: 0;
            font-size: 0;
            line-height: 0;
            border-top: 13px solid transparent;
            border-left: 26px solid rgba(0, 92, 255, 1);
            border-bottom: 13px solid transparent;
          }
        }
      }
    }
    .right {
      margin-top: 178px;
      width: 457px;
      height: 506px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .product {
    display: flex;
    justify-content: center;
    background: #000;
    img {
      width: 1131px;
      height: 700px;
    }
  }
  .Agility {
    background: rgba(0, 0, 0, 1);
    padding: 103px 11px 0px;
    .titleBox {
      display: flex;
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 30px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 259px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
      .title {
        margin-right: 73px;
      }
    }
    .itemBox {
      margin-top: 21px;
      padding: 0px 10px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 100px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 460px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 460px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
        .table {
          display: flex;
          .item {
            margin-right: 22px;
          }
        }
      }
      .itemE {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemF {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 458px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemG {
        position: relative;
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 460px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(0, 92, 255, 0.5);
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 53px;
          left: 146px;
          width: 233px;
          height: 200px;
          background: url('../../../assets/A90配图/PC/尺寸.png') no-repeat
            center;
          background-size: cover;
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
